import React from 'react'
import { graphql, withPrefix, Link } from 'gatsby'
import styled from 'styled-components'
import { connect } from 'react-redux'
// import InViewMonitor from 'react-inview-monitor'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import SEO from '../../components/seo'

import * as Layout from '../../layouts/layout'
import * as FadeIn from '../../components/fadeIn'

const Stores = ({ lang }) => (
  <>
    <SEO title="Stores" />
    <Hero className="hero">
      <HeroHeader>
        <FadeIn.Left margin="0%">
          <h1>
            {lang === 'ja' && '店鋪一覧'}
            {lang === 'en' &&
              'I want to convey the true taste of authentic Sichuan cuisine'}
            {lang === 'zh' && '店鋪概览'}
          </h1>
        </FadeIn.Left>

        <FadeIn.Left margin="0%">
          <p>
            {lang === 'ja' &&
              '笑顔を添えて、本物の四川料理の美味しさを提供します'}
            {lang === 'en' &&
              'I want to convey the true taste of authentic Sichuan cuisine'}
            {lang === 'zh' &&
              '给客人送上亲切的“笑容”和“地地道道的、真正的四川料理的美味”。'}
          </p>
        </FadeIn.Left>
      </HeroHeader>
    </Hero>
    <Layout.Section>
      <Layout.Container>
        <Header>
          <FadeIn.Left>
            <h2>
              {lang === 'ja' && '芙蓉苑 本店'}
              {lang === 'en' && 'Fuyo En'}
              {lang === 'zh' && '芙蓉苑 本店'}
            </h2>
          </FadeIn.Left>
        </Header>
        <Layout.Row style={{ alignItems: 'stretch' }}>
          <Layout.Col tablet={6}>
            <img src={withPrefix('/img/store-1.jpg')} alt="" />
          </Layout.Col>
          <Layout.Col tablet={6}>
            <GoogleMap>
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1646.2965484540337!2d135.5054860516772!3d34.67486103650909!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6000e79c742f5797%3A0x53cd91239e1a7808!2z5Zub5bed5paZ55CG6IqZ6JOJ6IuRIOmVt-WggOapi-acrOW6lw!5e0!3m2!1sen!2sjp!4v1568166630149!5m2!1sen!2sjp"
                width="600"
                height="450"
                frameBorder="0"
                allowFullScreen
              />
            </GoogleMap>
          </Layout.Col>
          {/* <Layout.Col tablet={6}>
            <Box>
              芙蓉苑には本場四川の食文化を大切に、本物の四川料理の味を日本に伝える使命があります。正真正銘「本物の四川料理」の美味しさを大切に「笑顔」を添えてお客様にお届けすることをお約束致します。
            </Box>
          </Layout.Col> */}
          <Layout.Col tablet={6}>
            {/* <TableBox>
              <Table>
                <thead>
                  <tr>
                    <TableHeader colSpan="2">
                      {lang === 'ja' && '平日'}
                      {lang === 'en' && 'Fuyo En'}
                      {lang === 'zh' && '平日'}
                      ｜11:00〜23:00(L.O 22:30)
                    </TableHeader>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <TableMenu>
                      {lang === 'ja' && 'ランチ'}
                      {lang === 'en' && 'Fuyo En'}
                      {lang === 'zh' && '午餐'}
                    </TableMenu>
                    <TableBody>11:00〜15:30(L.O 15:00)</TableBody>
                  </tr>
                  <tr>
                    <TableMenu>
                      {lang === 'ja' && 'ディナー'}
                      {lang === 'en' && 'Fuyo En'}
                      {lang === 'zh' && '晚餐'}
                    </TableMenu>
                    <TableBody>18:00〜23:00(L.O 22:30)</TableBody>
                  </tr>
                </tbody>
              </Table>
              <Table>
                <thead>
                  <tr>
                    <TableHeader colSpan="2">
                      {lang === 'ja' && '土日祝終日'}
                      {lang === 'en' && 'Fuyo En'}
                      {lang === 'zh' && '周六周日节假日'}
                      ｜11:00〜23:00(L.O 22:30)
                    </TableHeader>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <TableMenu>
                      {lang === 'ja' && 'グランドメニューのみ'}
                      {lang === 'en' && 'Fuyo En'}
                      {lang === 'zh' && '只有单品菜单'}
                    </TableMenu>
                    <TableBody>
                      11:00〜15:30(L.O 15:00) / 17:00〜23:00(L.O 22:30)
                    </TableBody>
                  </tr>
                </tbody>
              </Table>
            </TableBox> */}
            <OpeningHours>
              <p>
                {lang === 'ja' ? 'ランチ' : '午餐'}
                ：11:00~15:00（L.O 14:30）
                <br />
                {lang === 'ja' ? 'ディナー' : '晚餐'}
                ：18:00~22:00（L.O 21:30）
              </p>
              {/* <p>
                {lang === 'ja' && '緊急事態宣言及び自治体要請等にともない営業時間が異なる場合がございます。'}
                {lang === 'zh' && '因应紧急事态宣言及行政单位政策，营业时间可能与表定不符'}
              </p> */}
            </OpeningHours>
          </Layout.Col>
        </Layout.Row>
        <Layout.Row
          style={{
            alignItems: 'flex-end',
          }}
        >
          <Layout.Col tablet={6}>
            <MoreButton
              href="https://tabelog.com/osaka/A2701/A270201/27089496/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {lang === 'ja' && '詳細を見る'}
              {lang === 'en' && 'Fuyo En'}
              {lang === 'zh' && '详细看这里'}
            </MoreButton>
          </Layout.Col>
          <Layout.Col tablet={6}>
            <p
              style={{
                textAlign: 'center',
                color: '#603813',
                marginTop: '5px',
                marginBottom: '5px',
              }}
            >
              {lang === 'ja' && 'ご予約はこちらから'}
              {lang === 'en' && 'Fuyo En'}
              {lang === 'zh' && '点击这里预约'}
            </p>
            <TelButton href="tel:070-4576-4585">070-4576-4585</TelButton>
          </Layout.Col>
        </Layout.Row>
      </Layout.Container>
    </Layout.Section>
    <Layout.Section>
      <Layout.Container>
        <Header>
          <FadeIn.Left>
            <h2>
              {lang === 'ja' && '芙蓉火鍋城 長堀橋店'}
              {lang === 'en' && 'Fuyo En'}
              {lang === 'zh' && '芙蓉火鍋城 長堀橋店'}
            </h2>
          </FadeIn.Left>
        </Header>
        <Layout.Row style={{ alignItems: 'stretch' }}>
          <Layout.Col tablet={6}>
            <img src={withPrefix('/img/store-2.jpg')} alt="" />
          </Layout.Col>
          <Layout.Col tablet={6}>
            <GoogleMap>
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3281.2002800384425!2d135.50435901523144!3d34.674894380441195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6000e73d88d2f791%3A0x787f003e8bf5e71c!2z6IqZ6JOJ6IuRIOeBq-mNi-Wfjg!5e0!3m2!1sen!2sjp!4v1568166691934!5m2!1sen!2sjp"
                width="600"
                height="450"
                frameBorder="0"
                allowFullScreen
              />
            </GoogleMap>
          </Layout.Col>
          {/* <Layout.Col tablet={6}>
            <Box>
              芙蓉苑には本場四川の食文化を大切に、本物の四川料理の味を日本に伝える使命があります。正真正銘「本物の四川料理」の美味しさを大切に「笑顔」を添えてお客様にお届けすることをお約束致します。
            </Box>
          </Layout.Col> */}
          <Layout.Col tablet={6}>
            {/* <TableBox>
              <Table>
                <thead>
                  <tr>
                    <TableHeader colSpan="2">17:00〜2:00(L.O 1:00)</TableHeader>
                  </tr>
                </thead>
              </Table>
            </TableBox> */}
            <OpeningHours>
              <p>
                17:00~23:00（L.O 22:30）
              </p>
              {/* <p>
                {lang === 'ja' && '緊急事態宣言及び自治体要請等にともない営業時間が異なる場合がございます。'}
                {lang === 'zh' && '因应紧急事态宣言及行政单位政策，营业时间可能与表定不符'}
              </p> */}
            </OpeningHours>
          </Layout.Col>
        </Layout.Row>
        <Layout.Row
          style={{
            alignItems: 'flex-end',
          }}
        >
          <Layout.Col tablet={6}>
            <MoreButton
              href="https://tabelog.com/osaka/A2701/A270201/27102144/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {lang === 'ja' && '詳細を見る'}
              {lang === 'en' && 'Fuyo En'}
              {lang === 'zh' && '详细看这里'}
            </MoreButton>
          </Layout.Col>
          <Layout.Col tablet={6}>
            <p
              style={{
                textAlign: 'center',
                color: '#603813',
                marginTop: '5px',
                marginBottom: '5px',
              }}
            >
              {lang === 'ja' && 'ご予約はこちらから'}
              {lang === 'en' && 'Fuyo En'}
              {lang === 'zh' && '点击这里预约'}
            </p>
            <TelButton href="tel:070-4576-4585">070-4576-4585</TelButton>
          </Layout.Col>
        </Layout.Row>
      </Layout.Container>
    </Layout.Section>
    <Layout.Section>
      <Layout.Container>
        <Header>
          <FadeIn.Left>
            <h2>
              {lang === 'ja' && '芙蓉麻婆麺 十三店'}
              {lang === 'en' && 'Fuyo En'}
              {lang === 'zh' && '芙蓉麻婆麺 十三店'}
            </h2>
          </FadeIn.Left>
        </Header>
        <Layout.Row style={{ alignItems: 'stretch' }}>
          <Layout.Col tablet={6}>
            <img src={withPrefix('/img/store-3.jpg')} alt="" />
          </Layout.Col>
          <Layout.Col tablet={6}>
            <GoogleMap>
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3279.3647931578403!2d135.4857521152327!3d34.72119818042917!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6000e5d20d9300f3%3A0xd881052dc45e9a79!2z6IqZ6JOJ6IuR!5e0!3m2!1sen!2sjp!4v1568166744463!5m2!1sen!2sjp"
                width="600"
                height="450"
                frameBorder="0"
                allowFullScreen
              />
            </GoogleMap>
          </Layout.Col>
          {/* <Layout.Col tablet={6}>
            <Box>
              芙蓉苑には本場四川の食文化を大切に、本物の四川料理の味を日本に伝える使命があります。正真正銘「本物の四川料理」の美味しさを大切に「笑顔」を添えてお客様にお届けすることをお約束致します。
            </Box>
          </Layout.Col> */}
          <Layout.Col tablet={6}>
            {/* <TableBox>
              <Table>
                <thead>
                  <tr>
                    <TableHeader colSpan="2">
                      {lang === 'ja' && '終日（定休日以外）'}
                      {lang === 'en' && 'Fuyo En'}
                      {lang === 'zh' && '全天（定休日除外）'}
                      ｜11:00〜23:00(L.O 22:30)
                      {lang === 'ja' && '※定休日 火曜日'}
                      {lang === 'en' && 'Fuyo En'}
                      {lang === 'zh' && '※定休日 星期二'}
                    </TableHeader>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <TableMenu>
                      {' '}
                      {lang === 'ja' && 'ランチ'}
                      {lang === 'en' && 'Fuyo En'}
                      {lang === 'zh' && '午餐'}
                    </TableMenu>
                    <TableBody>11:00〜15:00(L.O 14:30)</TableBody>
                  </tr>
                  <tr>
                    <TableMenu>
                      {' '}
                      {lang === 'ja' && 'ディナー'}
                      {lang === 'en' && 'Fuyo En'}
                      {lang === 'zh' && '晚餐'}
                    </TableMenu>
                    <TableBody>17:00〜23:00(L.O 22:30)</TableBody>
                  </tr>
                </tbody>
              </Table>
            </TableBox> */}
            <OpeningHours>
              <p>
                {lang === 'ja' ? 'ランチ' : '午餐'}
                ：11:00～15:00(L.O 14:30)
                <br />
                {lang === 'ja' ? 'ディナー' : '晚餐'}
                ：17:00～23:00(L.O 22:30)
              </p>
              {/* <p>
                {lang === 'ja' && '緊急事態宣言及び自治体要請等にともない営業時間が異なる場合がございます。'}
                {lang === 'zh' && '因应紧急事态宣言及行政单位政策，营业时间可能与表定不符'}
              </p> */}
            </OpeningHours>
          </Layout.Col>
        </Layout.Row>
        <Layout.Row
          style={{
            alignItems: 'flex-end',
          }}
        >
          <Layout.Col tablet={6}>
            <MoreButton
              href="https://tabelog.com/osaka/A2701/A270302/27097472/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {lang === 'ja' && '詳細を見る'}
              {lang === 'en' && 'Fuyo En'}
              {lang === 'zh' && '详细看这里'}
            </MoreButton>
          </Layout.Col>
          <Layout.Col tablet={6}>
            <p
              style={{
                textAlign: 'center',
                color: '#603813',
                marginTop: '5px',
                marginBottom: '5px',
              }}
            >
              {lang === 'ja' && 'ご予約はこちらから'}
              {lang === 'en' && 'Fuyo En'}
              {lang === 'zh' && '点击这里预约'}
            </p>
            <TelButton href="tel:0648626996">06-4862-6996</TelButton>
          </Layout.Col>
        </Layout.Row>
      </Layout.Container>
    </Layout.Section>
    {/* <Layout.Section>
      <Layout.Container>
        <Header>
          <FadeIn.Left>
            <h2>
              {lang === 'ja' && '芙蓉麻婆麺 三ノ宮店'}
              {lang === 'en' && 'Fuyo En'}
              {lang === 'zh' && '芙蓉麻婆麺 三ノ宮店'}
            </h2>
          </FadeIn.Left>
        </Header>
        <Layout.Row style={{ alignItems: 'stretch' }}>
          <Layout.Col tablet={6}>
            <img src={withPrefix('/img/store-4.jpg')} alt="" />
          </Layout.Col>
          <Layout.Col tablet={6}>
            <GoogleMap>
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3280.5613782183036!2d135.18889521523184!3d34.69101808043685!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60008f07d0e1b433%3A0xc932639c2bcb18c3!2z6IqZ6JOJ6bq75amG6bq6IOS4ieODjuWuruW6lw!5e0!3m2!1sen!2sjp!4v1568166786132!5m2!1sen!2sjp"
                width="600"
                height="450"
                frameBorder="0"
                allowFullScreen
              />
            </GoogleMap>
          </Layout.Col>
          <Layout.Col tablet={6}>
            <Box>
              芙蓉苑には本場四川の食文化を大切に、本物の四川料理の味を日本に伝える使命があります。正真正銘「本物の四川料理」の美味しさを大切に「笑顔」を添えてお客様にお届けすることをお約束致します。
            </Box>
          </Layout.Col>
          <Layout.Col tablet={6}>
            <TableBox>
              <Table>
                <thead>
                  <tr>
                    <TableHeader colSpan="2">
                      {lang === 'ja' && '終日（定休日以外）'}
                      {lang === 'en' && 'Fuyo En'}
                      {lang === 'zh' && '全天（定休日除外）'}
                      ｜11:00〜23:00(L.O 22:30)
                      {lang === 'ja' && '※定休日 木曜日'}
                      {lang === 'en' && 'Fuyo En'}
                      {lang === 'zh' && '※定休日 星期四'}
                    </TableHeader>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <TableMenu>
                      {' '}
                      {lang === 'ja' && 'ランチ'}
                      {lang === 'en' && 'Fuyo En'}
                      {lang === 'zh' && '午餐'}
                    </TableMenu>
                    <TableBody>11:00〜15:00(L.O 14:30)</TableBody>
                  </tr>
                  <tr>
                    <TableMenu>
                      {lang === 'ja' && 'ディナー'}
                      {lang === 'en' && 'Fuyo En'}
                      {lang === 'zh' && '晚餐'}
                    </TableMenu>
                    <TableBody>17:00〜23:00(L.O 22:30)</TableBody>
                  </tr>
                </tbody>
              </Table>
            </TableBox>
          </Layout.Col>
        </Layout.Row>
        <Layout.Row
          style={{
            alignItems: 'flex-end',
          }}
        >
          <Layout.Col tablet={6}>
            <MoreButton
              href="https://tabelog.com/hyogo/A2801/A280101/28053761/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {lang === 'ja' && '詳細を見る'}
              {lang === 'en' && 'Fuyo En'}
              {lang === 'zh' && '详细看这里'}
            </MoreButton>
          </Layout.Col>
          <Layout.Col tablet={6}>
            <p
              style={{
                textAlign: 'center',
                color: '#603813',
                marginTop: '5px',
                marginBottom: '5px',
              }}
            >
              {lang === 'ja' && 'ご予約はこちらから'}
              {lang === 'en' && 'Fuyo En'}
              {lang === 'zh' && '点击这里预约'}
            </p>
            <TelButton href="tel:070-4576-4585">070-4576-4585</TelButton>
          </Layout.Col>
        </Layout.Row>
      </Layout.Container>
    </Layout.Section> */}
    <Layout.Section>
      <Layout.Container>
        <Header>
          <FadeIn.Left>
            <h2>
              {lang === 'ja' && '芙蓉火鍋城 京都店'}
              {lang === 'en' && 'Fuyo En'}
              {lang === 'zh' && '芙蓉火鍋城 京都店'}
            </h2>
          </FadeIn.Left>
        </Header>
        <Layout.Row style={{ alignItems: 'stretch' }}>
          <Layout.Col tablet={6}>
            <img src={withPrefix('/img/store-5.jpg')} alt="" />
          </Layout.Col>
          <Layout.Col tablet={6}>
            <GoogleMap>
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3267.9506492548903!2d135.7685017152404!3d35.00794048035684!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x600109561615e623%3A0x8041eb9508e56c55!2z6IqZ6JOJ6IuR54Gr6Y2L5Z-OIOS6rOmDveW6lw!5e0!3m2!1sen!2sjp!4v1568166838954!5m2!1sen!2sjp"
                width="600"
                height="450"
                frameBorder="0"
                allowFullScreen
              />
            </GoogleMap>
          </Layout.Col>
          {/* <Layout.Col tablet={6}>
            <Box>
              芙蓉苑には本場四川の食文化を大切に、本物の四川料理の味を日本に伝える使命があります。正真正銘「本物の四川料理」の美味しさを大切に「笑顔」を添えてお客様にお届けすることをお約束致します。
            </Box>
          </Layout.Col> */}
          <Layout.Col tablet={6}>
            {/* <TableBox>
              <Table>
                <thead>
                  <tr>
                    <TableHeader colSpan="2">
                      17:00〜26:00(L.O 25:30)
                    </TableHeader>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <TableMenu>
                      {lang === 'ja' && 'ディナー'}
                      {lang === 'en' && 'Fuyo En'}
                      {lang === 'zh' && '晚餐'}
                    </TableMenu>
                    <TableBody>17:00〜26:00(L.O 25:30)</TableBody>
                  </tr>
                </tbody>
              </Table>
            </TableBox> */}
            <OpeningHours>
              <p>
                15:00~23:00（L.O 22:00）
              </p>
              {/* <p>
                {lang === 'ja' && '緊急事態宣言及び自治体要請等にともない営業時間が異なる場合がございます。'}
                {lang === 'zh' && '因应紧急事态宣言及行政单位政策，营业时间可能与表定不符'}
              </p> */}
            </OpeningHours>
          </Layout.Col>
        </Layout.Row>
        <Layout.Row
          style={{
            alignItems: 'flex-end',
          }}
        >
          <Layout.Col tablet={6}>
            <MoreButton
              href="https://tabelog.com/kyoto/A2601/A260301/26032604/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {lang === 'ja' && '詳細を見る'}
              {lang === 'en' && 'Fuyo En'}
              {lang === 'zh' && '详细看这里'}
            </MoreButton>
          </Layout.Col>
          <Layout.Col tablet={6}>
            <p
              style={{
                textAlign: 'center',
                color: '#603813',
                marginTop: '5px',
                marginBottom: '5px',
              }}
            >
              {lang === 'ja' && 'ご予約はこちらから'}
              {lang === 'en' && 'Fuyo En'}
              {lang === 'zh' && '点击这里预约'}
            </p>
            <TelButton href="tel:0757548205">075-754-8205</TelButton>
          </Layout.Col>
        </Layout.Row>
      </Layout.Container>
    </Layout.Section>
    {/* <Layout.Section>
      <Layout.Container>
        <Header>
          <FadeIn.Left>
            <h2>
              {lang === 'ja' && 'FUYOENりんくう公園店'}
              {lang === 'en' && 'Fuyo En'}
              {lang === 'zh' && 'FUYOENりんくう公園店'}
            </h2>
          </FadeIn.Left>
        </Header>
        <Layout.Row style={{ alignItems: 'stretch' }}>
          <Layout.Col tablet={6}>
            <img src={withPrefix('/img/store-6.jpg')} alt="" />
          </Layout.Col>
          <Layout.Col tablet={6}>
            <GoogleMap>
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4067.86633708752!2d135.26260787267657!3d34.38024913725454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xbe9b3c4ceefe21c2!2sRinkuminamihama%20Beaches%20(Tarui%20Southern%20Beach)!5e0!3m2!1sen!2sjp!4v1593235841232!5m2!1sen!2sjp"
                width="600"
                height="450"
                frameBorder="0"
                allowFullScreen
              />
            </GoogleMap>
          </Layout.Col>
          <Layout.Col tablet={6}>
            <Box>
              芙蓉苑には本場四川の食文化を大切に、本物の四川料理の味を日本に伝える使命があります。正真正銘「本物の四川料理」の美味しさを大切に「笑顔」を添えてお客様にお届けすることをお約束致します。
            </Box>
          </Layout.Col>
          <Layout.Col tablet={6}>
            <TableBox>
              <Table>
                <thead>
                  <tr>
                    <TableHeader colSpan="2">
                      11:00〜21:00 (L.O 20:30)
                    </TableHeader>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <TableMenu>
                      {lang === 'ja' && 'ランチ'}
                      {lang === 'en' && 'Fuyo En'}
                      {lang === 'zh' && '午餐'}
                    </TableMenu>
                    <TableBody>11:00〜15:00(L.O 14:30)</TableBody>
                  </tr>
                </tbody>
              </Table>
            </TableBox>
            <OpeningHours>
              <p>
                11:00～21:00 (L.O 20:30)
              </p>
              <p>
                {lang === 'ja' && '緊急事態宣言及び自治体要請等にともない営業時間が異なる場合がございます。'}
                {lang === 'zh' && '因应紧急事态宣言及行政单位政策，营业时间可能与表定不符'}
              </p>
            </OpeningHours>
          </Layout.Col>
        </Layout.Row>
        <Layout.Row
          style={{
            alignItems: 'flex-end',
          }}
        >
          <Layout.Col tablet={6}>
            <MoreButton
              href="https://fuyoen-rinku.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {lang === 'ja' && '詳細を見る'}
              {lang === 'en' && 'Fuyo En'}
              {lang === 'zh' && '详细看这里'}
            </MoreButton>
          </Layout.Col>
          <Layout.Col tablet={6}>
            <p
              style={{
                textAlign: 'center',
                color: '#603813',
                marginTop: '5px',
                marginBottom: '5px',
              }}
            >
              {lang === 'ja' && 'ご予約はこちらから'}
              {lang === 'en' && 'Fuyo En'}
              {lang === 'zh' && '点击这里预约'}
            </p>
            <TelButton href="tel:0724477030">072-447-7030</TelButton>
          </Layout.Col>
        </Layout.Row>
      </Layout.Container>
    </Layout.Section> */}
    {/* <Layout.Section>
      <Layout.Container>
        <Header>
          <FadeIn.Left>
            <h2>
              {lang === 'ja' && 'FUYOEN心斎橋PARCO店'}
              {lang === 'en' && 'FUYOEN心斎橋PARCO店'}
              {lang === 'zh' && 'FUYOEN心斎橋PARCO店'}
            </h2>
          </FadeIn.Left>
        </Header>
        <Layout.Row style={{ alignItems: 'stretch' }}>
          <Layout.Col tablet={6}>
            <img src={withPrefix('/img/store-7.jpg')} alt="" />
          </Layout.Col>
          <Layout.Col tablet={6}>
            <GoogleMap>
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d820.3097104958076!2d135.5005191381446!3d34.67392099877161!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6000e710c66a9f1d%3A0x3dbc1d54599d7b4b!2sPARCO%20Shinsaibashi!5e0!3m2!1sen!2sjp!4v1614917550923!5m2!1sen!2sjp"
                width="600"
                height="450"
                frameBorder="0"
                allowFullscreen
              />
            </GoogleMap>
          </Layout.Col>
          <Layout.Col tablet={6}>
            <Box>
              芙蓉苑には本場四川の食文化を大切に、本物の四川料理の味を日本に伝える使命があります。正真正銘「本物の四川料理」の美味しさを大切に「笑顔」を添えてお客様にお届けすることをお約束致します。
            </Box>
          </Layout.Col>
          <Layout.Col tablet={6}>
            <TableBox>
              <Table>
                <thead>
                  <tr>
                    <TableHeader colSpan="2">
                      11:00〜21:00 (L.O 20:30)
                    </TableHeader>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <TableMenu>
                      -
                    </TableMenu>
                    <TableBody>11:00〜0:00（L.O 23:00）</TableBody>
                  </tr>
                </tbody>
              </Table>
            </TableBox>
            <OpeningHours>
              <p>
                11:00~22:00（L.O 21:30）
              </p>
              <p>
                {lang === 'ja' && '緊急事態宣言及び自治体要請等にともない営業時間が異なる場合がございます。'}
                {lang === 'zh' && '因应紧急事态宣言及行政单位政策，营业时间可能与表定不符'}
              </p>
            </OpeningHours>
          </Layout.Col>
        </Layout.Row>
        <Layout.Row
          style={{
            alignItems: 'flex-end',
          }}
        >
          <Layout.Col tablet={6}>
            <MoreButton
              href="https://tabelog.com/osaka/A2701/A270201/27121824/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {lang === 'ja' && '詳細を見る'} 
              {lang === 'en' && 'Fuyo En'}  
              {lang === 'zh' && '详细看这里'} 
            </MoreButton>
          </Layout.Col>
          <Layout.Col tablet={6}>
            <p
              style={{
                textAlign: 'center',
                color: '#603813',
                marginTop: '5px',
                marginBottom: '5px',
              }}
            >
              {lang === 'ja' && 'ご予約はこちらから'}
              {lang === 'en' && 'Fuyo En'}
              {lang === 'zh' && '点击这里预约'}
            </p>
            <TelButton href="tel:0664848278">06-6484-8278</TelButton>
          </Layout.Col>
        </Layout.Row>
      </Layout.Container>
    </Layout.Section> */}
    {/*<Layout.Section>
      <Layout.Container>
        <Header>
          <FadeIn.Left>
            <h2>
              {lang === 'ja' && '狐食堂 韓国料理Someck＆FUYOENエンタメ酒場 京都店'}
              {lang === 'en' && '狐食堂 韓国料理Someck＆FUYOENエンタメ酒場 京都店'}
              {lang === 'zh' && '狐食堂 韓国料理Someck＆FUYOENエンタメ酒場 京都店'}
            </h2>
          </FadeIn.Left>
        </Header>
        <Layout.Row style={{ alignItems: 'stretch' }}>
          <Layout.Col tablet={6}>
            <img src={withPrefix('/img/store-9.jpg')} alt="" />
          </Layout.Col>
          <Layout.Col tablet={6}>
            <GoogleMap>
              {/* <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4067.86633708752!2d135.26260787267657!3d34.38024913725454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xbe9b3c4ceefe21c2!2sRinkuminamihama%20Beaches%20(Tarui%20Southern%20Beach)!5e0!3m2!1sen!2sjp!4v1593235841232!5m2!1sen!2sjp"
                width="600"
                height="450"
                frameBorder="0"
                allowFullScreen
              />
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d8835.886867951429!2d135.77070861295275!3d35.00857849257651!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf3f4c50bd95afcc6!2z54uQ6aOf5aCCIOmfk-WbveaWmeeQhlNvbWVja--8hkZVWU9FTiDjgqjjg7Pjgr_jg6HphZLloLQg5Lqs6YO95bqX!5e0!3m2!1sen!2sjp!4v1629452131078!5m2!1sen!2sjp"
                width="600"
                height="450"
                frameBorder="0"
                allowFullscreen
              />
            </GoogleMap>
          </Layout.Col>
          {/* <Layout.Col tablet={6}>
            <Box>
              芙蓉苑には本場四川の食文化を大切に、本物の四川料理の味を日本に伝える使命があります。正真正銘「本物の四川料理」の美味しさを大切に「笑顔」を添えてお客様にお届けすることをお約束致します。
            </Box>
          </Layout.Col>
          <Layout.Col tablet={6}>
            {/* <TableBox>
              <Table>
                <thead>
                  <tr>
                    <TableHeader colSpan="2">
                      11:00〜21:00 (L.O 20:30)
                    </TableHeader>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <TableMenu>
                      -
                    </TableMenu>
                    <TableBody>11:00〜0:00（L.O 23:00）</TableBody>
                  </tr>
                </tbody>
              </Table>
            </TableBox>
            <OpeningHours>
              <p>
                平日：19:00~4:00
                <br />
                土日祝：18:00~5:00
              </p>
              <p>
                {lang === 'ja' && '緊急事態宣言及び自治体要請等にともない営業時間が異なる場合がございます。'}
                {lang === 'zh' && '因应紧急事态宣言及行政单位政策，营业时间可能与表定不符'}
              </p>
            </OpeningHours>
          </Layout.Col>
        </Layout.Row>
        <Layout.Row
          style={{
            alignItems: 'flex-end',
          }}
        >
          <Layout.Col tablet={6}>
            <MoreButtonTop
              href="https://www.hotpepper.jp/strJ001274954/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {lang === 'ja' && '詳細を見る (FUYOEN)'} 
              {lang === 'en' && 'Fuyo En'}  
              {lang === 'zh' && '详细看这里'} 
            </MoreButtonTop>
            <MoreButton
              href="https://www.hotpepper.jp/strJ001274929/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {lang === 'ja' && '詳細を見る (Someck)'} 
              {lang === 'en' && 'Fuyo En'}  
              {lang === 'zh' && '详细看这里'} 
            </MoreButton>
          </Layout.Col>
          <Layout.Col tablet={6}>
            {/* <p
              style={{
                textAlign: 'center',
                color: '#603813',
                marginTop: '5px',
                marginBottom: '5px',
              }}
            >
              {lang === 'ja' && 'ご予約はこちらから'}
              {lang === 'en' && 'Fuyo En'}
              {lang === 'zh' && '点击这里预约'}
            </p>
            <TelButton href="tel:0757416665">075-741-6665</TelButton>
          </Layout.Col>
        </Layout.Row>
      </Layout.Container>
          </Layout.Section>*/}
    <Layout.Section>
      <Layout.Container>
        <Header>
          <FadeIn.Left>
            <h2>
              {lang === 'ja' && '芙蓉苑渋谷PARCO店'}
              {lang === 'en' && '芙蓉苑渋谷PARCO店'}
              {lang === 'zh' && '芙蓉苑渋谷PARCO店'}
            </h2>
          </FadeIn.Left>
        </Header>
        <Layout.Row style={{ alignItems: 'stretch' }}>
          <Layout.Col tablet={6}>
            <img src={withPrefix('/img/store-11.jpg')} alt="" />
          </Layout.Col>
          <Layout.Col tablet={6}>
            <GoogleMap>
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4025.618079786999!2d139.6978108045001!3d35.66199157499452!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe7a6a8111363a5d9!2z6IqZ6JOJ6IuRIOa4i-iwt1BBUkNP5bqX!5e0!3m2!1sen!2sjp!4v1640091558586!5m2!1sen!2sjp"
                width="600"
                height="450"
                frameBorder="0"
                allowFullscreen
              />  
            </GoogleMap>
            {/* <ComingSoon><p>秋オープン</p></ComingSoon> */}
          </Layout.Col>
          {/* <Layout.Col tablet={6}>
            <Box>
              芙蓉苑には本場四川の食文化を大切に、本物の四川料理の味を日本に伝える使命があります。正真正銘「本物の四川料理」の美味しさを大切に「笑顔」を添えてお客様にお届けすることをお約束致します。
            </Box>
          </Layout.Col> */}
          <Layout.Col tablet={6}>
            {/* <TableBox>
              <Table>
                <thead>
                  <tr>
                    <TableHeader colSpan="2">
                      11:00～23:00（L.O 22:30）
                    </TableHeader>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <TableMenu>
                      -
                    </TableMenu>
                    <TableBody>11:00〜0:00（L.O 23:00）</TableBody>
                  </tr>
                </tbody>
              </Table>
            </TableBox> */}
            <OpeningHours>
              <p>
                11:30～23:00（L.O 22:00）
              </p>
              {/* <p>
                {lang === 'ja' && '緊急事態宣言及び自治体要請等にともない営業時間が異なる場合がございます。'}
                {lang === 'zh' && '因应紧急事态宣言及行政单位政策，营业时间可能与表定不符'}
              </p> */}
            </OpeningHours>
          </Layout.Col>
        </Layout.Row>
        <Layout.Row
          style={{
            alignItems: 'flex-end',
          }}
        >
          <Layout.Col tablet={6}>
            <MoreButtonTop
              href="https://www.hotpepper.jp/strJ001282744/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {/* Coming Soon */}
              {lang === 'ja' && '詳細を見る　(HotPepper)'} 
              {lang === 'en' && 'Fuyo En (HotPepper)'}  
              {lang === 'zh' && '详细看这里　(HotPepper)'} 
            </MoreButtonTop>
            <MoreButton
              href="https://tabelog.com/tokyo/A1303/A130301/13265888/"
              target="_blank"
              rel="noopener noreferrer"
            > 
              {lang === 'ja' && '詳細を見る (Tabelog)'}
              {lang === 'en' && 'Fuyo En (Tabelog)'}
              {lang === 'zh' && '详细看这里 (Tabelog)'}
            </MoreButton>
          </Layout.Col>
          <Layout.Col tablet={6}>
            {/* <p
              style={{
                textAlign: 'center',
                color: '#603813',
                marginTop: '5px',
                marginBottom: '5px',
              }}
            >
              {lang === 'ja' && 'ご予約はこちらから'}
              {lang === 'en' && 'Fuyo En'}
              {lang === 'zh' && '点击这里预约'}
            </p> */}
            <TelButton href="tel:0364550212">03-6455-0212</TelButton>
          </Layout.Col>
        </Layout.Row>
      </Layout.Container>
    </Layout.Section>
    <Layout.Section>
      <Layout.Container>
        <Header>
          <FadeIn.Left>
            <h2>
              {lang === 'ja' && 'FUYOEN & SOMECK CHINESE × KOREAN'}
              {lang === 'en' && 'FUYOEN & SOMECK CHINESE × KOREAN'}
              {lang === 'zh' && 'FUYOEN & SOMECK CHINESE × KOREAN'}
            </h2>
          </FadeIn.Left>
        </Header>
        <Layout.Row style={{ alignItems: 'stretch' }}>
          <Layout.Col tablet={6}>
            <img src={withPrefix('/img/store-temp.jpg')} alt="" />
          </Layout.Col>
          <Layout.Col tablet={6}>
            <GoogleMap>
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3298.632172400183!2d135.1882946757243!3d34.23240922308967!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6000b3267c797f8d%3A0xd8ff6ca790b3b6a8!2z5ZKM5q2M5bGxTUlP!5e0!3m2!1sja!2sjp!4v1707193673352!5m2!1sja!2sjp"
                width="600"
                height="450"
                frameBorder="0"
                allowFullscreen
              />
            </GoogleMap>
            {/* <ComingSoon><p>秋オープン</p></ComingSoon> */}
          </Layout.Col>
          {/* <Layout.Col tablet={6}>
            <Box>
              芙蓉苑には本場四川の食文化を大切に、本物の四川料理の味を日本に伝える使命があります。正真正銘「本物の四川料理」の美味しさを大切に「笑顔」を添えてお客様にお届けすることをお約束致します。
            </Box>
          </Layout.Col> */}
          <Layout.Col tablet={6}>
            {/* <TableBox>
              <Table>
                <thead>
                  <tr>
                    <TableHeader colSpan="2">
                      11:00〜21:00 (L.O 20:30)
                    </TableHeader>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <TableMenu>
                      -
                    </TableMenu>
                    <TableBody>11:00〜0:00（L.O 23:00）</TableBody>
                  </tr>
                </tbody>
              </Table>
            </TableBox> */}
            <OpeningHours>
              <p>
                11:30～21:00
              </p>
              {/* <p>
                {lang === 'ja' && '緊急事態宣言及び自治体要請等にともない営業時間が異なる場合がございます。'}
                {lang === 'zh' && '因应紧急事态宣言及行政单位政策，营业时间可能与表定不符'}
              </p> */}
            </OpeningHours>
          </Layout.Col>
        </Layout.Row>
        <Layout.Row
          style={{
            alignItems: 'flex-end',
          }}
        >
          <Layout.Col tablet={6}>
            <MoreButtonTop
              href="https://www.hotpepper.jp/strJ003297042/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {/* Coming Soon */}
              {lang === 'ja' && '詳細を見る　(HotPepper)'} 
              {lang === 'en' && 'Fuyo En (HotPepper)'}  
              {lang === 'zh' && '详细看这里　(HotPepper)'} 
            </MoreButtonTop>
            <MoreButton
              href="https://tabelog.com/wakayama/A3001/A300101/30010790/"
              target="_blank"
              rel="noopener noreferrer"
            > 
              {lang === 'ja' && '詳細を見る (Tabelog)'}
              {lang === 'en' && 'Fuyo En (Tabelog)'}
              {lang === 'zh' && '详细看这里 (Tabelog)'}
            </MoreButton>
          </Layout.Col>
          <Layout.Col tablet={6}>
            {/* <p
              style={{
                textAlign: 'center',
                color: '#603813',
                marginTop: '5px',
                marginBottom: '5px',
              }}
            >
              {lang === 'ja' && 'ご予約はこちらから'}
              {lang === 'en' && 'Fuyo En'}
              {lang === 'zh' && '点击这里预约'}
            </p> */}
            <TelButton href="tel:0731887566">073-488-7566</TelButton>
          </Layout.Col>
        </Layout.Row>
      </Layout.Container>
    </Layout.Section>
  </>
)

export const pageQuery = graphql`
  {
    allWordpressPost {
      edges {
        node {
          id
          title
          date(formatString: "YYYY/MM/DD")
          slug
        }
      }
    }
  }
`

const mapStateToProps = state => ({
  lang: state.lang,
})

export default connect(mapStateToProps)(Stores)

const pink = '#e3007f'
const beige = '#fadcbe'
// const lightBeige = '#FEF8F2'
const brown = '#603813'
const darkBrown = '#42210B'

const Hero = styled.div`
  position: relative;
  height: 66.6666666666vw;
  max-height: 600px;
  overflow: hidden;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(/img/hero.jpg) center/cover;
  animation: 15s ${Layout.ZoomBg} infinite;
`

const HeroHeader = styled.header`
  position: absolute;
  transform: translate(-50%, -50%);
  color: #fff;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: calc(100% - 60px);
  @media (min-width: 1025px) {
    max-width: 450px;
    left: 30%;
  }
  h1 {
    line-height: 2;
    letter-spacing: 10px;
    font-size: 1.5rem;
  }
  p {
    line-height: 1.4;
    letter-spacing: 5px;
  }
`

const Header = styled.header`
  position: relative;
  margin-bottom: 30px;
  h2 {
    color: ${brown};
    margin-left: 20px;
  }
  &::before {
    position: absolute;
    content: '';
    display: block;
    width: 5px;
    height: 45px;
    background: ${brown};
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
  @media (min-width: 415px) {
    h2 {
      margin-left: 30px;
    }
    &::before {
      height: 64px;
      width: 10px;
    }
  }
`

const GoogleMap = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
  padding-top: 75%;
  @media (min-width: 415px) {
    padding-top: 0;
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
`

// const Box = styled.div`
//   padding: 15px;
//   color: ${brown};

// `

const TableBox = styled.div`
  color: ${brown};
  margin-top: 15px;
  margin-bottom: 15px;
`

const Table = styled.table`
  border: 1px solid ${darkBrown};
  width: 100%;
`

const TableHeader = styled.th`
  background: ${darkBrown};
  border: 1px solid ${darkBrown};
  color: #fff;
  text-align: center;
  padding: 5px;
  width: 100%;
  colspan: 2;
`

const TableMenu = styled.td`
  color: ${darkBrown};
  border: 1px solid ${darkBrown};
  width: 30%;
  text-align: center;
  padding: 5px;
  vertical-align: middle;
`

const TableBody = styled.td`
  color: ${darkBrown};
  border: 1px solid ${darkBrown};
  width: 70%;
  text-align: center;
  padding: 5px;
  vertical-align: middle;
`

const MoreButton = styled.a`
  background: linear-gradient(90deg, ${pink} 0%, ${beige} 100%);
  color: #fff;
  width: 100%;
  text-align: center;
  padding: 15px;
  font-size: 18px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.5s ease;
  @media (min-width: 415px) {
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.4);
    &:hover {
      box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
    }
  }
`

const TelButton = styled.a`
  background: linear-gradient(90deg, ${brown} 0%, ${beige} 100%);
  color: #fff;
  width: 100%;
  text-align: center;
  padding: 15px;
  font-size: 18px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.5s ease;
  @media (min-width: 415px) {
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.4);
    &:hover {
      box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
    }
  }
`

const OpeningHours = styled.div`
  margin: 1rem 0;
  text-align: center;

  p {
    margin: 0 0 1rem;

    &:last-child {
      margin: 0;
      padding: 1rem 0 0;
      border-top: 1px solid black;
    }
  }
`;

const ComingSoon = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
  padding-top: 75%;
  
  @media (min-width: 415px) {
    padding-top: 0;
  }

  p {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
    color: #6a6a6a;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
  }
`

const MoreButtonTop = styled(MoreButton)`
  margin-bottom: 1rem;

  @media (max-width: 414px) {
    margin-bottom: 0;
  }
`